


















































































































































import Vue from 'vue'

export default Vue.extend({
  pageOptions: {
    path: '/outputs-of-learning',
    name: "OutputsOfLearning",
    title: "Outputs of Learning",
  },
})
