








































import Vue from 'vue'
export default Vue.extend({
  name: 'AtomCheckbox',
  inheritAttrs: false,
  model: {
    event: 'update:modelValue',
    prop: 'modelValue',
  },
  props: {
    modelValue: {
      required: false,
    },
    value: {
      type: [String, Boolean, Object],
      required: false,
      default: true,
    },
    uncheckedValue: {
      type: [String, Boolean, Object],
      required: false,
      default: false,
    },
    justify: {
      required: false,
      default: 'flex-start',
    },
    label: {
      type: String,
    },
  },
  computed: {
    checked () {
      if (Array.isArray(this.modelValue)) {
        return !!this.modelValue.find(value => this.isEqual(value, this.value))
      } else {
        return this.isEqual(this.modelValue, this.value)
      }
    },
  },
  methods: {
    update (event): void {
      if (this.checked) {
        this.$emit('update:modelValue', this.uncheckedValue)
        this.$emit('input', this.uncheckedValue)
      } else {
        this.$emit('update:modelValue', this.value)
        this.$emit('input', this.value)
      }
    },
    isEqual (valueA, valueB): boolean {
      return valueA === valueB || (valueA?.id && valueA?.id === valueB?.id)
    },
  },
})
