
import Vue from 'vue'

import { cssVarProps, modifierProps } from '@/library-imports/css-variable-props'
import BaseElement from '../BaseElement.vue'

const boxVariants = {
  default: {

  },
}

export default Vue.extend({
  extends: BaseElement,
  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
  mixins: [
    cssVarProps({
      position: {
        default: 'relative',
      },
      padding: {
        constantKey: 'spacing',
      },

      width: { constantKey: 'spacing' },
      height: { constantKey: 'spacing' },
      maxWidth: { constantKey: 'spacing' },
      maxHeight: { constantKey: 'spacing' },

      borderRadius: {},
      border: {
        constantKey: 'color',
      },
      borderTop: {
        constantKey: 'color',
        overwrite: false,
      },
      borderRight: {
        constantKey: 'color',
        overwrite: false,
      },
      borderBottom: {
        constantKey: 'color',
        overwrite: false,
      },
      borderLeft: {
        constantKey: 'color',
        overwrite: false,
      },

      overflow: {
        default: 'visible',
      },

      // Colors
      color: {
        constantKey: 'color',
      },
      bgColor: {
        constantKey: 'color',
      },

      align: {},
      boxShadow: {},
    }),
  ],
  computed: {
    variants () {
      return boxVariants
    },
  },

})
