import { render, staticRenderFns } from "./ATag.vue?vue&type=template&id=1caf71ba&scoped=true&"
import script from "./ATag.vue?vue&type=script&lang=ts&"
export * from "./ATag.vue?vue&type=script&lang=ts&"
import style0 from "./ATag.vue?vue&type=style&index=0&id=1caf71ba&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1caf71ba",
  null
  
)

export default component.exports