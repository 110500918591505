








































































































































































































































































import Vue from 'vue'

export default Vue.extend({
  pageOptions: {
    path: '/general-problem-solving',
    name: "General Problem Solving",
    title: "General Problem Solving",
  },
})
