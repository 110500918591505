const constants = {
  spacing: {
    xxsmall: '4px',
    xsmall: '8px',
    small: '12px',
    'gutter-small': '16px',
    gutter: '20px',
    'gutter-large': '32px',
    medium: '24px',
    large: '40px',
    xlarge: '52px',
    xxlarge: '80px',
    xxxlarge: '180px',
    1: '0.25rem',
    2: '0.5rem',
    3: '0.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',

    'mx-auto': '0 auto',
  },
  limit: {
    mobile: '400px',
    xsmall: '500px',
    small: '725px',
    medium: '1000px',
    large: '1200px',
    xlarge: '1440px',
  },
  color: {
    primary: '#2F736C',

    'green-100': '#020E0D',
    'green-80': '#173532',
    'green-60': '#2F736C',
    'green-40': '#49A669',
    'green-30': '#96D8D1',
    'green-10': '#DEF7F5',

    link: '#49A669',
    'link-hover': '#49A669',
    'link-visited': '#2F736C',

    'light-brown': '#D9B19A',
    brown: '#BF544B',
    fushia: '#F24472',
    blue: '#03588C',
    'baby-blue': '#77CFD9',
    tan: '#D98D62',
    coral: '#F25D50',
    purple: '#732F68',

    sand: '#F2E0C9',

    'pink-60': '#D98282',

    'yellow-60': '#D9C45B',

    'grey-10': '#F2F2F2',
    'grey-30': '#CBCBCB',
    'grey-50': '#565656',
    'grey-70': '#434343',
    'grey-90': '#2F2F2F',
  },
  boxShadow: {
    light: '0 0 10px #57667B48',
    medium: '0 2px 10px #AABACF48',
    heavy: '0 3px 6px #00000024, 0 6px 24px #00000024',
    wide: '0 0 20px #071B1F',
  },
  borderRadius: {
    xsmall: '3px',
    small: '5px',
    medium: '10px',
    large: '20px',
    round: '99999px',
  },
  fontSize: {
    1: '2.75rem',
    2: '2rem',
    3: '1.5rem',
    4: '1.25rem',
    5: '1rem',
    6: '1rem',
    p: '1rem',
    'small-medium': '0.875rem',
    small: '0.75rem',
    normal: '1rem',
    'medium-small': '1.125rem',
    medium: '1.5rem',
    'medium-large': '1.75rem',
    large: '3rem',
    xlarge: '5rem',
    xxlarge: '10rem',
    xsmall: '0.5rem',
  },
  fontWeight: {
    light: '200',
    book: '300',
    roman: '400',
    medium: '500',
    heavy: '700',
    black: '900',
  },
  fontFamily: {
    heading: "'Josefin Sans', sans-serif",
    text: "'Quicksand', sans-serif",
    alt: "'Inconsolata', monospace",
  },
  lineHeight: {
    small: '1.2em',
    medium: '1.5em',
    large: '2em',
    double: '2em',
  },
  iconSize: {
    medium: '1rem',
    small: '0.75rem',
    large: '2.5rem',
  },
}

Object.freeze(constants)

export default constants
