




























































































































































































































































































import Vue from 'vue'

export default Vue.extend({
  pageOptions: {
    path: '/concept-cartography-101',
    name: "Concepts 101",
    title: "Concept Engineering 101",
  },
})
