import VueRouter, { RouteConfig } from 'vue-router'
import VueConstructor from 'vue'
import { Component } from 'vue-router/types/router'

const routes: Array<RouteConfig> = []

const requirePages = require.context(
  '@/pages/', false, /[\w-]+\.vue$/,
)

requirePages.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requirePages(fileName)

  const component: Component = componentConfig.default || componentConfig

  console.log(component);

  const {
    path,
    name,
    title,
  } = (component as any)?.extendOptions?.pageOptions

  if (path) {
    routes.push({
      path,
      name,
      meta: {
        title: title || name,
      },
      component,
    })
  }
})

const p: Record<string, RouteConfig> = {}

export const pages = routes.reduce((prev: Record<string, RouteConfig>, next: RouteConfig) => {
  prev[next.name.replaceAll(' ', '')] = next
  return prev
}, p)

export default routes
