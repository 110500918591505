
import Vue from 'vue'

import { cssVarProps, modifierProps } from '@/library-imports/css-variable-props'
import BaseElement from '../BaseElement.vue'

export default Vue.extend({
  extends: BaseElement,
  mixins: [
    cssVarProps({
      spacing: {
        constantKey: 'spacing',
        default: 'medium',
      },
    }),
  ],
  computed: {
    componentName() {
      return "spacer"
    },
  },
})
