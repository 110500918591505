
import Vue from 'vue'

import { cssVarProps, modifierProps } from '@/library-imports/css-variable-props'
import BaseElement from '../BaseElement.vue'
import { mapActions } from 'vuex'

const buttonVariants = {
  default: {
    colorA: 'white',
    colorB: 'green-60',
    colorC: 'white',
    colorD: 'green-80',

    border: 'none',
    borderRadius: '5px',
  },
  neutral: {
    colorA: 'grey-30',
    colorB: 'grey-70',
    colorC: 'grey-50',
    colorD: 'grey-70',

    border: '1px solid grey-30',
    borderRadius: '5px',
  },
}

export default Vue.extend({
  extends: BaseElement,
  mixins: [
    cssVarProps({
      align: {},
      border: {
        constantKey: 'color',
      },
      borderRadius: {
        constantKey: 'borderRadius',
      },
      colorA: {
        constantKey: 'color',
      },
      colorB: {
        constantKey: 'color',
      },
      colorC: {
        constantKey: 'color',
      },
      colorD: {
        constantKey: 'color',
      },
    }),
    modifierProps({
      size: {
        type: 'string',
        default: 'small',
      },
      fullwidth: {
        type: 'boolean',
        default: false,
      },
    }),
  ],
  computed: {
    variants () {
      return buttonVariants
    },
  },
  methods: {
    // ...mapActions([
    //   'trackClick',
    // ]),
  },
})
