




























































import Vue from 'vue'

export default Vue.extend({
  pageOptions: {
    path: '/concept-cartography-101/concept-types',
    name: "ConceptTypes",
    title: "Concept Types",
  },
})
