
import Vue from 'vue'

import { cssVarProps, modifierProps } from '@/library-imports/css-variable-props'

import BaseElement from '../BaseElement.vue'

export default Vue.extend({
  extends: BaseElement,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  mixins: [
    cssVarProps({
    }),
    modifierProps({
      size: {
        type: 'string',
        default: 'medium',
      },
    }),
  ],
  props: {
    options: {
      type: Array,
      required: true,
    },
    textKey: {
      type: String,
      default: 'text',
    },
    valueKey: {
      type: String,
      default: 'value',
    },
    placeholder: {
      type: String,
      required: false,
    },
    modelValue: {
      type: [String, Object],
    },
    emitOption: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    modelValueOption () {
      const modelValue = this.modelValue

      return this.getOption(modelValue)
    },
  },
  methods: {
    getOption (value) {
      if (!value) return

      const valueKey = this.valueKey

      return this.options.find((option) => {
        // Use Double equals because sometimes id is string and sometimes number
        // eslint-disable-next-line eqeqeq
        return option === value || option[valueKey] == value
      })
    },
    getText (option) {
      if (option === undefined || option === null) {

      } else if (typeof option === 'number') {
        return String(option)
      } else if (typeof option === 'string') {
        return option
      } else {
        return option[this.textKey]
      }
    },
    getValue (option) {
      if (option === undefined || option === null) {

      } else if (typeof option === 'number') {
        return String(option)
      } else if (typeof option === 'string') {
        return option
      } else {
        return option[this.valueKey]
      }
    },
  },
})
