






























































































import Vue from 'vue'

export default Vue.extend({
  pageOptions: {
    path: '/',
    name: "Home",
    title: "Concept Cartography",
  },
})
