






















import Vue from 'vue'

export default Vue.extend({
  pageOptions: {
    path: '/concepts-and-frameworks',
    name: "ConceptList",
    title: "Concept Page",
  },
})
