
import Vue from 'vue'

import { cssVarProps, modifierProps } from '@/library-imports/css-variable-props'
import BaseElement from '../BaseElement.vue'

export default Vue.extend({
  extends: BaseElement,
  props: {
    icon: {
      type: String,
      default: 'question-circle',
    },
  },
  mixins: [
    cssVarProps({
      size: {
        constantKey: 'iconSize',
        default: 'medium',
      },
    }),
    modifierProps({

    }),
  ],
})
