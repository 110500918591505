






















import Vue from 'vue'

export default Vue.extend({
  pageOptions: {
    path: '/applied-learning',
    name: "AppliedLearning",
    title: "Applied Learning",
  },
})
