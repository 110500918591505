









import Vue from 'vue'
import NavigationHeader from '@/compositions/NavigationHeader.vue'

import constants from '@/blocks/constants'

export default Vue.extend({
  components: {
    NavigationHeader,
  },
  computed: {
    cssVars() {
      const constantTypes = constants
      const ruleParts = [];

      Object.entries(constantTypes).map(([type, constants]) => {

        Object.entries(constants).forEach(([name, value]) => {
          ruleParts.push(`--${type}-${name}: ${value};`)
        })
      })

      return ruleParts.join('')
    },
  },
})
