






















import Vue from 'vue'

export default Vue.extend({
  pageOptions: {
    path: '/execution-processes',
    name: "ExecutionProcesses",
    title: "Execution Processes",
  },
})
