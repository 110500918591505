




































import HStack from '@/blocks/layouts/HStack.vue'
import Vue from 'vue'

export default Vue.extend({
  components: { HStack },
    
})
