
import Vue from 'vue'

import { cssVarProps, modifierProps } from '@/library-imports/css-variable-props'
import BaseElement from '../BaseElement.vue'

export default Vue.extend({
  extends: BaseElement,
  props: {
    level: {
      type: [Number, String],
      default: '3',
    },
  },
  mixins: [
    cssVarProps({
      size: {
        constantKey: 'fontSize',
        usesComputed: true,
      },
      align: {},
      weight: {
        constantKey: 'fontWeight',
        default: 'heavy',
      },
      family: {
        constantKey: 'fontFamily',
        default: 'heading',
      },
      letterSpacing: {},
      lineHeight: {
        default: 'small',
      },
      color: {},
      transform: {
        constantKey: 'textTransform',
      },
      whiteSpace: {},
    }),
  ],
  computed: {
    tag () {
      return 'h' + this.level
    },
    _size () {
      return this.size || this.level || '3'
    },
  },
})
