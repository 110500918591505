
import Vue from 'vue'

import { cssVarProps, modifierProps } from '@/library-imports/css-variable-props'
import BaseElement from '../BaseElement.vue'

export default Vue.extend({
  extends: BaseElement,
  mixins: [
    cssVarProps({
      listStyle: {},
      spacing: {
        default: 'small',
      },
      padding: {
        constantKey: 'spacing',
        default: 'medium',
      },
    }),
  ],
  props: {
    ordered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    componentName() {
      return "a-list" 
    },
  },
})
