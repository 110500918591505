
import Vue from 'vue'

import { cssVarProps, modifierProps } from '@/library-imports/css-variable-props'
import BaseElement from '../BaseElement.vue'

export default Vue.extend({
  extends: BaseElement,
  props: {
    tag: {
      type: String,
      default: 'p',
    },
  },
  mixins: [
    cssVarProps({
      size: {
        constantKey: 'fontSize',
        default: 'p',
      },
      align: {},
      weight: {
        constantKey: 'fontWeight',
      },
      family: {
        constantKey: 'fontFamily',
        default: 'text',
      },
      fontStyle: {},
      letterSpacing: {},
      lineHeight: { default: 'medium' },
      color: {},
      transform: {
        constantKey: 'textTransform',
      },
      whiteSpace: {},
    }),
  ],
})
