
import Vue from 'vue'

import { cssVarProps, modifierProps } from '@/library-imports/css-variable-props'
import BaseElement from '../BaseElement.vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  extends: BaseElement,
  props: {
    to: {
      type: String,
      required: true,
    },
    newTab: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [
    cssVarProps({
      
    }),
    modifierProps({
      
    }),
  ],
  computed: {
    componentName() {
      return "a-link"
    },
    target() {
      return this.newTab ? "_blank" : "_self"
    },
    isLocalRoute(): boolean {
      return !this.to.match(/^http/)
    },
  },
  methods: {
    scrollToTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      })
    },
  },
})
