






















import Vue from 'vue'

export default Vue.extend({
  pageOptions: {
    path: '/wip',
    name: "WorkInProgress",
    title: "WorkInProgress",
  },
})
