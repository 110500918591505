
import Vue from 'vue'

import { cssVarProps, modifierProps } from '@/library-imports/css-variable-props'
import BaseElement from '../BaseElement.vue'

export default Vue.extend({
  extends: BaseElement,
  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
  computed: {
    directionClass () {
      return Array.isArray(this.direction)
        ? (
          this.direction.map((dir, index) => `b${index}-${dir}`)
        )
        : (
          `b0-${this.direction} b1-${this.direction} b2-${this.direction}`
        )
    },
  },
  mixins: [
    cssVarProps({
      direction: {
        default: 'row',
      },
      spacing: {
        default: 'xsmall',
      },
      justify: {
        default: 'flex-start',
      },
      align: {
        default: 'center',
      },
    }),
    modifierProps({
      wrap: {
        type: 'boolean',
        default: false,
      },
    }),
  ],
})
